import {
  Container,
  Typography,
  Paper,
  Box,
  Stack,
  Button,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { roundNumber } from "../../../hooks/roundNumber";
import ModelOpen from "../../../core/Model copy";

export interface IModel {
  open: boolean;
  handleClose: () => void;
  cashAmt: number;
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  callApiHandler: () => void;
  amount: number;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onModelHandler: () => void;
  paymentMode: "UPI" | "Cash";
  setPaymentMode: (data: "UPI" | "Cash") => void;
}

const paymentType = ["UPI", "Cash"];

const ConfirmTosted = ({
  open,
  handleClose,
  title,
  subTitle,
  amount,
  callApiHandler,
  handleInputChange,
  cashAmt,
  onModelHandler,
  paymentMode,
  setPaymentMode,
}: IModel) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [type, setType] = useState<"UPI" | "Cash">(paymentMode);

  // Ref to the container for focus management
  const containerRef = useRef<HTMLDivElement>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);

  // Focus the container when the modal opens or when the type changes
  useEffect(() => {
    if (open && containerRef.current) {
      containerRef.current.focus();
    }
    if (open && type === "Cash" && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [open, type]);

  // Handle keydown events for shortcuts
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault(); // Prevent default behavior (e.g., scrolling)
      if (event.key === "ArrowLeft") {
        setType("UPI");
        setPaymentMode("UPI");
      } else if (event.key === "ArrowRight") {
        setType("Cash");
        setPaymentMode("Cash");
      }
    }

    // Handle Enter key press
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission behavior
      handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>); // Trigger form submission
    }
  };

  // Handle toggle button change
  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: "UPI" | "Cash"
  ) => {
    if (newType !== null) {
      setType(newType);
      setPaymentMode(newType);
    }
  };

  const handleInputBlur = () => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (type === "Cash") {
      if (cashAmt >= Math.abs(cashAmt - roundNumber(amount))) {
        callApiHandler();
      } else {
        alert(
          "Attention: The entered cash amount does not match the bill total."
        );
      }
    } else {
      callApiHandler();
    }
  };

  return (
    <ModelOpen
      open={open}
      handleClose={handleClose}
      onModelHandler={onModelHandler}
      title={title}
      subTitle={subTitle}
      width="450px"
    >
      <form className="column" onSubmit={handleSubmit}>
        <Container
          maxWidth="lg"
          component={Paper}
          elevation={2}
          sx={{ width: "100%" }}
          onKeyDown={handleKeyDown} // Add keydown listener to the container
          tabIndex={0} // Make the container focusable
          ref={containerRef} // Ref to the container for focus management
        >
          <Box py={5}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
              alignItems={"center"}
              height={"100%"}
            >
              <Typography fontWeight={"900"} sx={{ fontSize: "20px" }}>
                Payment Type :
              </Typography>

              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={handleTypeChange}
                aria-label="payment type"
              >
                {paymentType.map((data) => (
                  <ToggleButton
                    key={data}
                    value={data}
                    aria-label={data}
                    sx={{ fontWeight: 900, fontSize: 20 }}
                  >
                    {data}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ display: type === "Cash" ? "flex" : "none" }}
              pt={2}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Received Amt :
              </Typography>

              <TextField
                value={cashAmt === 0 ? "" : cashAmt}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                autoFocus={true}
                type="number"
                size="medium"
                inputRef={textFieldRef} // Ref to the TextField
                sx={{
                  fontSize: "25px",
                  fontWeight: 900,
                  textAlign: "center",
                  width: "100px",
                  "& .MuiOutlinedInput-input": {
                    fontWeight: 900,
                    fontSize: "25px",
                    border: "none",
                  },
                }}
              />
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} pt={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Total :
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
                color={"primary"}
              >
                {amount.toFixed(2)}
              </Typography>
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} pt={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Round Off :
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
                color={"primary"}
              >
                {amount - roundNumber(amount) > 0 ? "-" : "+"}
                {Math.abs(roundNumber(amount) - amount).toFixed(2)}
              </Typography>
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} pt={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Net :
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
                color={"primary"}
              >
                {roundNumber(amount) + ".00"}
              </Typography>
            </Stack>

            {type === "Cash" && (
              <>
                <Box sx={{ border: "2px dashed" }}></Box>

                {cashAmt >= roundNumber(amount) && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    pt={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"primary"}
                    >
                      Return :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"primary"}
                    >
                      {cashAmt - roundNumber(amount) + ".00"}
                    </Typography>
                  </Stack>
                )}

                {cashAmt < roundNumber(amount) && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    pt={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"error"}
                    >
                      Pending :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"error"}
                    >
                      {Math.abs(cashAmt - roundNumber(amount)) + ".00"}
                    </Typography>
                  </Stack>
                )}
              </>
            )}

            <Stack direction={"row"} gap={"10px"} pt={2}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                sx={{ py: 2, fontWeight: 900, fontSize: "20px" }}
              >
                Pay Now
              </Button>
            </Stack>
          </Box>
        </Container>
      </form>
    </ModelOpen>
  );
};

export default ConfirmTosted;
