import create from "zustand";
import { IReceiveList } from "../models/IReceiveList";

type Store = {
  data: IReceiveList[] | undefined;
  setData: (data: IReceiveList[]) => void;
};

const useReceiveOrderStore = create<Store>(
  (set): Store => ({
    data: undefined,
    setData(data) {
      set({ data: data });
    },
  })
);
export default useReceiveOrderStore;
