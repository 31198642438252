import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { ITHead } from "../../models/Common/ITHead";
import { IItemList } from "../../models/POSModel/IItemList";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Id",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Qty(Kg)",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "Gross Rate",
    isMove: false,
    align: "center",
  },

  {
    id: 2,
    name: "Amount",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "",
    isMove: false,
    align: "right",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Id",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "(Kg/PCS)",
    isMove: false,
    align: "left",
  },
  {
    id: 3,
    name: "Gross Rate",
    isMove: false,
    align: "left",
  },
  {
    id: 4,
    name: "Discount",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Amount",
    isMove: false,
    align: "center",
  },
];

export const users = [...Array(24)].map((_, index) => ({
  id: faker.string.uuid(),
  avatarUrl: ``,
  name: faker.person.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  status: sample(["active", "banned"]),
  role: sample([
    "Leader",
    "Hr Manager",
    "UI Designer",
    "UX Designer",
    "UI/UX Designer",
    "Project Manager",
    "Backend Developer",
    "Full Stack Designer",
    "Front End Developer",
    "Full Stack Developer",
  ]),
}));

export const item: IItemList[] = [
  {
    barcode: "123456789",
    productName: "Widget",
    qty: 2,
    grossRate: 100,
    discount: 10,
    amount: 180,
    savings: 20,
    _id: "6616e68b685f166ca5d81835",
  },
  {
    barcode: "987654321",
    productName: "Gadget",
    qty: 1,
    grossRate: 50,
    discount: 5,
    amount: 45,
    savings: 5,
    _id: "6616e68b685f166ca5d81836",
  },

  {
    barcode: "123456789",
    productName: "Widget",
    qty: 2,
    grossRate: 100,
    discount: 10,
    amount: 180,
    savings: 20,
    _id: "6616e68b685f166ca5d81835",
  },
  {
    barcode: "987654321",
    productName: "Gadget",
    qty: 1,
    grossRate: 50,
    discount: 5,
    amount: 45,
    savings: 5,
    _id: "6616e68b685f166ca5d81836",
  },
  {
    barcode: "123456789",
    productName: "Widget",
    qty: 2,
    grossRate: 100,
    discount: 10,
    amount: 180,
    savings: 20,
    _id: "6616e68b685f166ca5d81835",
  },
  {
    barcode: "987654321",
    productName: "Gadget",
    qty: 1,
    grossRate: 50,
    discount: 5,
    amount: 45,
    savings: 5,
    _id: "6616e68b685f166ca5d81836",
  },
  {
    barcode: "123456789",
    productName: "Widget",
    qty: 2,
    grossRate: 100,
    discount: 10,
    amount: 180,
    savings: 20,
    _id: "6616e68b685f166ca5d81835",
  },
  {
    barcode: "987654321",
    productName: "Gadget",
    qty: 1,
    grossRate: 50,
    discount: 5,
    amount: 45,
    savings: 5,
    _id: "6616e68b685f166ca5d81836",
  },
];

export const receiveOrder = [...Array(3)].map((_, index) => ({
  id: faker.string.uuid(),
  particular: `Order ${index + 1}`,
  date: faker.date.past().getDate(),
  time: sample(["05:30 am", "08:00 am", "07:00 am", "06:30 am", "07:10 am"]),
  driver: sample(["Ramesh", "Raju", "Jayesh"]),
}));
