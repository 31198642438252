export interface IMenuItem {
  id: number;
  name: string;
  iconify: string;
  path?: string;
  list?: IMenuItem[];
}

export const branchManager: IMenuItem[] = [
  {
    id: 2,
    name: "Place Order",
    iconify: "lets-icons:order-light",
    list: [
      {
        id: 1,
        name: "View Order",
        iconify: "lets-icons:view-light",
        path: "/branch-manager/view",
      },
      {
        id: 2,
        name: "Add Order",
        iconify: "mingcute:add-fill",
        path: "/branch-manager/add",
      },
    ],
  },

  {
    id: 3,
    name: "Receive Order",
    iconify: "lets-icons:order-light",
    path: "/branch-manager/receive-order",
  },
  {
    id: 3,
    name: "Order Report",
    iconify: "lets-icons:order-light",
    path: "/branch-manager/order-report",
  },

  {
    id: 3,
    name: "Purchase Report",
    iconify: "lets-icons:order-light",
    path: "/branch-manager/purchase-report",
  },
  {
    id: 3,
    name: "Print Price Banner",
    iconify: "lets-icons:order-light",
    path: "/branch-manager/price",
  },
  {
    id: 3,
    name: "Sales",
    iconify: "lets-icons:order-light",
    path: "/branch-manager/sales",
  },
  {
    id: 3,
    name: "Payment Transaction",
    path: "/payment-list",
    iconify: "fluent:payment-32-regular",
  },
  {
    id: 4,
    name: "Direct Purchase",
    path: "/direct-purchase",
    iconify: "mingcute:directions-2-line",
  },
  {
    id: 4,
    name: "Supplier List",
    path: "/branch-manager/suppliers",
    iconify: "carbon:scis-transparent-supply",
  },
  {
    id: 4,
    name: "Driver List",
    path: "/branch-manager/driver-list",
    iconify: "carbon:scis-transparent-supply",
  },
];

export const purchaseManager: IMenuItem[] = [
  {
    id: 2,
    name: "Buy Order",
    path: "/buy-order",
    iconify: "lets-icons:order-light",
  },
  {
    id: 3,
    name: "Supplier Order",
    path: "/supplier",
    iconify: "solar:shop-linear",
  },
  {
    id: 3,
    name: "Purchase Report",
    path: "/purchase/report",
    iconify: "line-md:document-report",
  },
  {
    id: 3,
    name: "Creditors Leger",
    path: "/supplier-ledger",
    iconify: "fluent-emoji-high-contrast:ledger",
  },
  {
    id: 4,
    name: "Majduri Leger",
    path: "/majduri-ledger",
    iconify: "fluent-emoji-high-contrast:ledger",
  },
  {
    id: 4,
    name: "Container Tracking",
    path: "/container-tracking",
    iconify: "lucide:container",
  },
  {
    id: 3,
    name: "Drivers",
    path: "/purchase-manager/driver",
    iconify: "eva:car-outline",
  },
  {
    id: 3,
    name: "All Supplier List",
    path: "/purchase-manager/supplier",
    iconify: "solar:shop-linear",
  },
  {
    id: 3,
    name: "Payment Transaction",
    path: "/payment-list",
    iconify: "fluent:payment-32-regular",
  },
  {
    id: 3,
    name: "All Product List ",
    path: "/product-list",
    iconify: "icon-park-outline:ad-product",
  },
  {
    id: 3,
    name: "All Container List ",
    path: "/container-list",
    iconify: "icon-park-outline:ad-product",
  },
];

export const procurementManager: IMenuItem[] = [
  {
    id: 2,
    name: "Supplier List",
    path: "/procurement/supplier-list",
    iconify: "lets-icons:order-light",
  },
];

export const allocationManager: IMenuItem[] = [
  {
    id: 2,
    name: "Allocate Order",
    path: "/Confirm-order",
    iconify: "lets-icons:order-light",
  },
];

export const isAllNav: IMenuItem[] = [
  {
    id: 1,
    name: "Dashboard",
    path: "/dashboard",
    iconify: "iconamoon:profile-fill",
  },
  {
    id: 2,
    name: "Sales Report",
    path: "/sale-report",
    iconify: "hugeicons:sale-tag-02",
  },
  {
    id: 2,
    name: "Stock Report ",
    path: "/Stock-report",
    iconify: "ant-design:stock-outlined",
  },
  {
    id: 4,
    name: "Receive Order ",
    path: "/receive-order",
    iconify: "ant-design:stock-outlined",
  },

  {
    id: 3,
    name: "Profile",
    path: "/profile",
    iconify: "iconamoon:profile-fill",
  },
];

export const navigationData = {
  branchManager,
  purchaseManager,
  procurementManager,
  allocationManager,
};
