import create from "zustand";

type Store = {
  driverId: string | undefined;
  setDriverId: (data: string | undefined) => void; // Optional parameter to allow clearing the data
};

const useSelectedDriverStore = create<Store>((set) => ({
  driverId: undefined,
  setDriverId(data: string | undefined) {
    set({ driverId: data });
  },
}));

export default useSelectedDriverStore;
