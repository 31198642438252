import { TableRow, TableCell, Stack, IconButton } from "@mui/material";
import Iconify from "../../../core/Iconify";
import { ItemsEntity } from "../../../models/POSModel/IBillList";
import { ProductServices } from "../../../services/ProductServices";
import { useState, useEffect, useRef } from "react";
import useBillListStore from "../../../store/POS/useBillListStore";
import { ArrivalsEntity } from "../../../models/IReceiveList";
// import { IItemList } from "../../../models/POSModel/IItemList";

// ----------------------------------------------------------------------
interface IProps {
  data: ArrivalsEntity[] | null | undefined;
  index: number;
}

// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const [loader, setLoader] = useState(false);

  const removeItemHandler = async () => {
    try {
      setLoader(true);
      // const response = await ProductServices.removeItemFromBillApi(
      //   data.productId._id,
      //   data.quantity,
      //   data.rate,
      //   billList?.billNo
      // );
      // if (response.status === 200 && response.data) {
      //   setBillList(response.data.bill);
      // }
    } catch (error) {
      console.error("Error fetching products", error);
      alert("Error in remove ");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          // Changed 'data' to 'item' to avoid naming conflict
          return (
            <TableRow
              hover
              role="checkbox"
              key={index} // Consider using a unique ID instead of index if available
              sx={{
                cursor: "pointer",
                background: (theme) =>
                  index % 2 === 1
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
              }}
              tabIndex={-1} // To make it focusable
            >
              <TableCell align="left">#{index + 1}</TableCell>
              <TableCell align="left">
                {item.driver?.nameOfDriver || "N/A"}
              </TableCell>
              <TableCell align="center">
                {(item.products && item.products[0]?.productId?.name) || "N/A"}
              </TableCell>
              <TableCell align="center">
                {(item.products && item.products[0]?.totalPcsToWarehouse) ||
                  "0"}
              </TableCell>
              <TableCell align="center">
                {(item.products && item.products[0]?.totalWeightToWarehouse) ||
                  "0"}
              </TableCell>
              <TableCell>
                <Stack direction="row" gap="10px" justifyContent="end">
                  <IconButton color="success">
                    <Iconify icon="mage:edit" />
                  </IconButton>
                  <IconButton color="error">
                    <Iconify icon="mingcute:delete-2-line" />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
};

export default TRow;
