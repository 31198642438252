import styled from "@emotion/styled";
import { Link } from "react-router-dom";
function Logo() {
  const Logo = styled("img")(() => ({
    height: "35px",
  }));
  return (
    <Link to={"/"}>
      {" "}
      <Logo src={"/logo.png"} alt={"WorldQart Logo"} loading="lazy" />
    </Link>
  );
}
export default Logo;
