import { Autocomplete, TextField } from "@mui/material";
import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { ProductServices } from "../services/ProductServices";
import { IProduct } from "../models/POSModel/IProduct";

// Props interface for the component
interface IProps {
  inputHandler: (value: IProduct | null) => void;
  isFullWidth?: boolean;
  isValid?: boolean;
}

// Interface for the ref actions to expose methods to parent components
export interface AllProductActions {
  // Export the interface
  clearInput: () => void;
  focus: () => void; // Ensure focus is included
}

const AllProduct = forwardRef<AllProductActions, IProps>(
  ({ inputHandler, isFullWidth = false, isValid = false }, ref) => {
    const [list, setList] = useState<IProduct[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    // Fetches all products and sets the list
    const getAllProductList = async () => {
      try {
        const response = await ProductServices.getAllProductApi(1, 200);
        if (response.status === 200 && response.data) {
          setList(response.data);
        }
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    // ComponentDidMount lifecycle to fetch products and focus the input
    useEffect(() => {
      getAllProductList();
      // Focus the input field on mount
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    // Method to clear the input field and notify the parent via `inputHandler`
    const clearInput = () => {
      setInputValue("");
      inputHandler(null);
    };

    // Method to focus the input field
    const focus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    // Expose the `clearInput` and `focus` methods to parent components through `ref`
    useImperativeHandle(ref, () => ({
      clearInput,
      focus,
    }));

    // Handles changes in the Autocomplete input
    const handleInputChange = (
      event: React.ChangeEvent<{}>,
      newInputValue: string
    ) => {
      setInputValue(newInputValue); // Update the state immediately
    };

    useEffect(() => {
      console.log("Updated inputValue:", inputValue);
    }, [inputValue]);

    // Filter options based on numberId or name
    const filterOptions = (options: IProduct[]) => {
      console.log("inputValue in filterOptions:", inputValue);

      const parsedNumber = Number(inputValue);
      const isNumberSearch = !isNaN(parsedNumber);
      console.log(
        "parsedNumber",
        parsedNumber,
        "isNumberSearch",
        isNumberSearch
      );

      // Priority 1: Match by numberId
      if (isNumberSearch) {
        const numberIdMatch = options.find(
          (product) => product.numberId === parsedNumber
        );
        console.log("numberIdMatch", numberIdMatch);

        if (numberIdMatch) {
          return [numberIdMatch]; // Return only the matching product
        }
      }

      // Priority 2: Match by name (case-insensitive)
      const nameMatches = options.filter((product) =>
        product.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      return nameMatches; // Return all name matches
    };

    return (
      <Autocomplete
        freeSolo
        sx={{
          width: isFullWidth ? "100%" : 300,
          "& .MuiAutocomplete-input": {
            fontWeight: 700,
            fontSize: "18px",
          },
        }}
        disableClearable
        options={list}
        value={inputValue}
        onInputChange={handleInputChange}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: string | IProduct | null
        ) => {
          if (typeof newValue === "string") {
            const matchingProduct = list.find(
              (product) =>
                product.name === newValue ||
                product.numberId === Number(newValue)
            );
            inputHandler(matchingProduct ? matchingProduct : null);
          } else {
            inputHandler(newValue);
          }
        }}
        filterOptions={(options, state) => filterOptions(options)} // Use the latest inputValue
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : `${option.numberId} - ${option.name}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search a Product"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            inputRef={inputRef}
            error={!!isValid}
            helperText={isValid ? "Select a valid product" : ""}
          />
        )}
        componentsProps={{
          popper: {
            sx: {
              "& .MuiAutocomplete-option": {
                fontWeight: "bold",
              },
            },
          },
        }}
        autoHighlight // Automatically highlight the first option
        autoSelect // Automatically select the first option when input changes
      />
    );
  }
);

export default AllProduct;
