import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  TableBody,
  TablePagination,
  Typography,
} from "@mui/material";
import { IState } from "../../../models/IState";
import Tosted from "../../../core/Tosted";
import THeader from "./THeader";
import TRow from "./TRow";
import { getTodayDate } from "../../../hooks/getTodayDate";
import { AllList, MobileList } from "../data";
import { BranchService } from "../../../services/Branch";
import { IReceivedOrder } from "../../../models/Branch/IReceivedOrder";
import THeaderView from "../../../core/THeaderView";
import MTable from "../../../core/MTable";
import Add from "./Add";
import Form from "./Form";
import useBillListStore from "../../../store/POS/useBillListStore";
import useReceiveOrderStore from "../../../store/useReceiveOrder";

const List = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [addItemOpen, setAddItemOpen] = useState(false);
  // const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [date, setDate] = useState<string>(getTodayDate());

  const { billList } = useBillListStore((state) => ({
    billList: state.data,
  }));

  const { data, setData } = useReceiveOrderStore();

  // Event handler for date change
  const isAddItemHandler = () => {
    setAddItemOpen(true);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F2") {
        isAddItemHandler();
      }
    };
    // Add event listener
    document.addEventListener("keydown", handleKeyDown);
    // Clean up the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%", mb: 2, p: 2 }} elevation={3}>
          <Typography variant="h4" textAlign={"start"} gutterBottom>
            Product In Shop
          </Typography>
          <Stack direction={"row"} gap={"10px"} height={"400px"}>
            <Stack
              direction={"row"}
              sx={{
                width: "400px",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Form isCheckprice={true} />
            </Stack>
            <Paper
              sx={{ width: "100%", height: "100%", overflow: "auto" }}
              elevation={3}
            >
              <THeader
                searchInputHandler={searchInputHandler}
                searchInput={searchInput}
              />

              <MTable>
                <THeaderView AllList={AllList} MobileList={MobileList} />

                <TableBody sx={{ height: "100%" }}>
                  {data &&
                    data.map((data, index) => {
                      return <TRow data={data.arrivals} index={index} />;
                    })}

                  {/* {data?.arrivals &&
                    data.arrivals.map((data, index) => {
                      return <TRow data={data} index={index} />;
                    })} */}
                </TableBody>
              </MTable>
            </Paper>
          </Stack>
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
