// stores/useSerialPortStore.ts
import { create } from "zustand";
import { SerialPort } from "../pages/POS-Order/core/Form";

interface SerialPortState {
  port: SerialPort | null;
  setPort: (port: SerialPort | null) => void;
}

export const useSerialPortStore = create<SerialPortState>((set) => ({
  port: null,
  setPort: (port) => set({ port }),
}));
