import { TableRow, TableCell, Stack, IconButton } from "@mui/material";
import Iconify from "../../../core/Iconify";
import { ItemsEntity } from "../../../models/POSModel/IBillList";
import { ProductServices } from "../../../services/ProductServices";
import { useState, useEffect, useRef, useCallback } from "react";
import useBillListStore from "../../../store/POS/useBillListStore";

// ----------------------------------------------------------------------
interface IProps {
  data: ItemsEntity;
  index: number;
  billNo?: number;
}

// ----------------------------------------------------------------------
const TRow = ({ data, index, billNo }: IProps) => {
  const [loader, setLoader] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { billList, setBillList } = useBillListStore((state) => ({
    billList: state.data,
    setBillList: state.setData,
  }));

  const lastRowRef = useRef<HTMLTableRowElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  // Memoize the removeItemHandler to avoid unnecessary re-renders
  const removeItemHandler = useCallback(
    async (index: number) => {
      try {
        setLoader(true);
        const itemToRemove = billList?.items?.[index];
        if (itemToRemove) {
          const response = await ProductServices.removeItemFromBillApi(
            itemToRemove.productId._id,
            itemToRemove.quantity,
            itemToRemove.rate,
            billList?.billNo
          );
          if (response.status === 200 && response.data) {
            setBillList(response.data.bill);
          }
        }
      } catch (error) {
        console.error("Error fetching products", error);
      } finally {
        setLoader(false);
      }
    },
    [billList, setBillList]
  );

  // Auto-scroll to the last row when a new item is added
  useEffect(() => {
    if (
      billList?.items &&
      index === billList.items.length - 1 &&
      lastRowRef.current
    ) {
      lastRowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [billList, index]);

  // Handle keydown events
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F2") {
        // Focus on the table
        tableRef.current?.focus();
        setSelectedIndex(0); // Select the first row
      } else if (event.key === "F1" && selectedIndex !== null) {
        // Prevent default behavior of F1 (e.g., opening browser help)
        event.preventDefault();
        // Remove the selected row
        removeItemHandler(selectedIndex);
      } else if (event.key === "ArrowDown" && selectedIndex !== null) {
        // Move selection down
        setSelectedIndex((prevIndex) =>
          prevIndex === null
            ? 0
            : Math.min(prevIndex + 1, (billList?.items?.length || 0) - 1)
        );
      } else if (event.key === "ArrowUp" && selectedIndex !== null) {
        // Move selection up
        setSelectedIndex((prevIndex) =>
          prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)
        );
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedIndex, billList, removeItemHandler]);

  return (
    <TableRow
      hover
      role="checkbox"
      key={index}
      ref={
        billList?.items && index === billList.items.length - 1
          ? lastRowRef
          : null
      }
      sx={{
        cursor: "pointer",
        background: (theme) =>
          index % 2 === 1
            ? theme.palette.background.default
            : theme.palette.background.paper,
        backgroundColor:
          selectedIndex === index ? "action.selected" : undefined,
      }}
      tabIndex={0} // To make it focusable
    >
      <TableCell align="left">#{index + 1}</TableCell>
      <TableCell align="left">{data.productId.name}</TableCell>
      <TableCell align="center">{data.quantity.toFixed(3)}</TableCell>
      <TableCell align="center">{data.rate.toFixed(2)}</TableCell>
      <TableCell align="center">
        {(data.quantity * data.rate).toFixed(2)}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" gap="10px" justifyContent="flex-end">
          <IconButton color="success">
            <Iconify icon="mage:edit" />
          </IconButton>
          <IconButton color="error" onClick={() => removeItemHandler(index)}>
            <Iconify icon="mingcute:delete-2-line" />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default TRow;
