import { List } from "./core";
import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import H1 from "../../core/H1";
import { getTodayDate } from "../../hooks/getTodayDate";

import { ProductServices } from "../../services/ProductServices";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useSelectedDriverStore from "../../store/POS/useSelectedDriverStore";
import { Driver } from "../../models/IGetReceivedOrder";

function ReceiveOrder() {
  const [driverList, setDriverList] = useState<Driver[]>([]);
  const { driverId, setDriverId } = useSelectedDriverStore();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setDriverId(selectedValue === "" ? undefined : selectedValue);
  };

  const [loader, setLoader] = useState(false);

  const [date, setDate] = useState<string>(getTodayDate());

  const getDriverHandler = async (billNo?: number) => {
    try {
      setLoader(true);
      const response = await ProductServices.getAllDriverApi();
      if (response.status === 200 && response.data) {
        console.log("drvier List", response.data);
        setDriverList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getDriverHandler();
  }, []);

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  return (
    <>
      <Stack direction={"row"} px={3} justifyContent={"space-between"} py={3}>
        <H1 title="Receive Order" />
      </Stack>
      <Stack direction={"row"} px={3} py={1}>
        <Grid container spacing={1.5}>
          <Grid item lg={9}>
            <Stack direction={"row"} gap={10} alignItems={"center"}>
              <Stack direction={"row"} gap={"20px "} alignItems={"center"}>
                <InputBase
                  type="date"
                  value={date}
                  disabled={true}
                  onChange={onDateChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      position: "relative",
                      backgroundColor: "background.paper",
                      border: (theme) => `1px solid #ccc`,
                      px: 1,
                      py: 1,
                      borderRadius: 3,
                    },
                  }}
                />
              </Stack>
              <Stack width={"200px"}>
                <FormControl sx={{ background: "#fff" }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Select Driver
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={driverId || ""}
                    onChange={handleChange}
                    label="Select Driver"
                  >
                    {driverList && driverList.length > 0 ? (
                      driverList.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.nameOfDriver}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No drivers available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction={"row"} px={3} py={0}>
        <Grid container spacing={1.5}>
          <Grid item lg={9}>
            <List />
          </Grid>
          <Grid item lg={3}>
            <Paper
              sx={{ width: "100%", height: "auto", mt: 1, p: 2 }}
              elevation={3}
            >
              <Stack direction={"row"} gap={"10px"} pb={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    fontWeight: 900,
                    border: "3px solid ",
                  }}
                  onClick={() => alert("save")}
                >
                  (F8)
                  <br />
                  Save Now
                </Button>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default ReceiveOrder;
