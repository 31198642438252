import { InstanceAxios, InstanceAxiosErp } from "../hooks/axiosConfig";
import { IReceiveOrder } from "../models/IReceiveOrder";
import { IBillAdd } from "../models/POSModel/IBillAdd";

export class ProductServices {
  // public static getAllProductApi() {
  //   return InstanceAxios().get("/product");
  // }
  public static getAllProductApi(page?: number, pagesize?: number) {
    return InstanceAxios().get("/pos/fetchProductNamesAndIds");
  }

  public static getProductPriceApi(productId: string) {
    return InstanceAxios().get("/pos/fetchSellingPriceByDate", {
      params: {
        productId: productId,
      },
    });
  }

  public static addOrUpdateBillApi(
    productId: string,
    quantity: number,
    rate: number,
    billNo?: number
  ) {
    let data = {
      productId: productId,
      quantity: quantity,
      rate: rate,
      billNo: billNo,
    };
    return InstanceAxios().post("/posbill/addOrUpdateBill", data);
  }

  public static receiveOrderApi(data: IReceiveOrder) {
    return InstanceAxios().post("/receiveOrder/addOrUpdateReceiveOrder", data);
  }

  public static getAllDriverApi() {
    return InstanceAxios().get("/driverDetails/getDriverDetails");
  }

  public static getReceivedOrderDetailsByDate(date: string) {
    return InstanceAxios().get(
      `/receiveOrder/getAllReceivedProductsWithDetails?processDate=${date}`
    );
  }

  public static removeItemFromBillApi(
    productId: string,
    quantity: number,
    rate: number,
    billNo?: number
  ) {
    let data = {
      productId: productId,
      quantity: quantity,
      rate: rate,
      billNo: billNo,
    };
    return InstanceAxios().delete("/posbill/removeItemFromBill", {
      data: data,
    });
  }

  public static addBillPaymentApi(data: IBillAdd) {
    return InstanceAxios().post("/posbill/addBillPayment", data);
  }

  public static getBillApi(billNo: number) {
    return InstanceAxios().get("/posbill/getBillByNumber", {
      params: {
        billNo: billNo,
      },
    });
  }

  public static addProductApi(
    name: string,
    description: string,
    category: string
  ) {
    let data = {
      name: name,
      description: description,
      category: category,
    };
    return InstanceAxios().post("/product/addProductAdmin", data);
  }
  public static updateProductApi(
    name: string,
    description: string,
    category: string,
    _id: string
  ) {
    let data = {
      name: name,
      description: description,
      category: category,
    };
    return InstanceAxios().put(`/product/updateproduct/${_id}`, data);
  }
  public static deleteProductApi(_id: string) {
    return InstanceAxios().delete(`/product/removeproduct/${_id}`);
  }
}
